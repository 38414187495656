import {
  BgHeader,
  PhoneSection,
  Solution,
} from "../../images/SingleCase/IllichRealty"

const dataForIllichRealty = {
  type: "project",
  smart: true,
  classComponent: "Illichrealty",
  nextLink: "cases/RZD-roads/",
  prevLink: "cases/fcteam/",
  data: {
    title: "Ilich Realty",
    subtitle:
      "The app was created to simplify real estate rent and sale in Odessa - popular tourist destination in the South of Ukraine.",
    overview: {
      title: "Project overview",
      about: {
        title: "About client",
        content:
          "The app was developed as In-house product for local community.",
      },
      challenge: {
        title: "Business challenge",
        content:
          "The application was built due to need to simplify real estate sale and rent situation in Odessa. It was crucial to assure great UX - so that real estate agents could easily list their’s or customers’ property. Customers can intuitively find what they need in short notice and receive as much information as they require, also save it to favorites as well as share it with friends and family.",
      },
      specific: [
        {
          type: "Platform",
          content: ["Android"],
          icon: "fa-th-large",
        },
        {
          type: "Technology",
          content: ["React Native", "Google Maps API", "PHP", "Symfony"],
          icon: "fa-cog",
        },
        {
          type: "Team",
          icon: "fa-users",
          content: [
            {
              type: "Designer",
              count: 1,
            },
            {
              type: "React Native Developers",
              count: 2,
            },
            {
              type: "QA Engineer",
              count: 1,
            },
            {
              type: "Project Manager",
              count: 1,
            },
          ],
        },
        {
          type: "Duration",
          icon: "fa-calendar",
          content: [
            {
              type: "1,5 month",
              count: "",
            },
          ],
        },
        {
          type: "Client",
          content: ["Own Product"],
          icon: "fa-user",
        },
      ],
    },
    solution: {
      title: "Solution",
      textFirst: [
        "A mobile app that allows real estate agents to list the potential property for rent. ",
        "On the other hand, potential renters can search the property through advanced filter system or geolocation services, save it to favorites as well as share it with friends.",
      ],
    },
    delivered: {
      title: "Value delivered",
      list: [
        "Good overall UX",
        "Advanced multi-layer filter system",
        "Favorites system",
        "Various sorting options",
        "Integration with Google Maps",
        "The app acts as a two-sided platform between real estate agents and consumers combining UIs that serve both sides.",
      ],
    },
    images: {
      bgImage: BgHeader,
      section3: PhoneSection,
      section5: Solution,
    },
  },
  imageSrc: [],
  relatedProjects: [
    {
      link: "/cases/2cents",
      id: "enjoy",
      title: "2cents",
    },
    {
      link: "/cases/crm-sap-integration",
      id: "crmSap",
      title: "CRM/SAP Integration",
    },
    {
      link: "/cases/gud-job",
      id: "gud-job",
      title: "Gud Job",
    },
    {
      link: "/cases/cartovera",
      id: "cartovera",
      title: "Cartovera",
    },
  ],
  link: "/cases/ilich-realty",
}

export default dataForIllichRealty
