import React from "react"

import OverviewComponent from "../OverviewComponent"

export default function Illichrealty({ data }) {
  let i = 0
  return [
    <section className="bgBlock bgImage Illichrealty" key={i++}>
      <div style={{ position: "absolute", width: "100%" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-5 col-sm-4 "
              style={{ textAlign: "right" }}
            >
              <div className="bgTop phone-bg"></div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="wave"
        style={{ backgroundImage: `url(${data.images.bgImage})` }}
      >
        <div className="container">
          <div className="row" id="illich-row">
            <div
              className="col-lg-5 col-md-7 col-sm-8 col-md-offset-5 col-sm-offset-4 col-lg-offset-7"
              style={{ height: "100%" }}
            >
              <div className="titleBlock">
                <h1 id="illich-title">IlichRealty</h1>
                <span id="illich-subheader">{data.subtitle}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>,

    <section className="Illichrealty compose" key={i++}>
      <div className="bgImage">
        <OverviewComponent {...data} classComponent="" />

        <section className="Illichrealty-phone image">
          <div className="transformBlock"></div>
          <div className="container">
            <div className="row">
              <img
                src={data.images.section3}
                alt="Illichrealty application"
                className="phone-section"
              />
            </div>
          </div>
        </section>

        <section className="solution">
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <h2 className="noMarginTop">{data.solution.title}</h2>
                {data.solution.textFirst.map((item, i) => (
                  <p key={i}>{item}</p>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="image-solution">
          <div className="imageBlock">
            <div className="image fixed">
              <img
                src={data.images.section5}
                alt="Illichrealty application"
                className="solution-picture"
              />
            </div>
          </div>
        </section>

        <section className="solution last">
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <h2 className="noMarginTop">{data.delivered.title}</h2>
                <ul>
                  {data.delivered.list.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>,
  ]
}
