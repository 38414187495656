import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import IllichRealty from "../../components/SingleCase/IllichRealty"
import ContactBtnBlock from "../../components/SingleCase/ContactBtnBlock"
import Navigate from "../../components/SingleCase/Navigate"
import RelatedProjects from "../../components/SingleCase/RelatedProjects"
import dataForIllichRealty from "../../information/Cases/dataForIllichRealty"
import { mainUrl } from "../../js/config"
import "../../components/SingleCase/PortfolioComponent.scss"

export default class IllichRealtyPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showForm: false,
    }
  }

  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm })
  }
  render() {
    const {
      classComponent,
      nextLink,
      prevLink,
      relatedProjects,
      data,
    } = dataForIllichRealty

    return (
      <section className="singleProject Illichrealty">
        <SEO
          title="IlichRealty Development Case — OS-System"
          description='Development Case of app for real estate - IlichRealty -how to create an application with real estate filter and geolocation services for simplify rent and sale'
          canonical={`${mainUrl}/cases/ilich-realty/`}
        />
        <Layout showFormPoint={100}>
          <IllichRealty data={data} />
          <Navigate
            classComponent={classComponent}
            nextLink={nextLink}
            prevLink={prevLink}
          />
          <ContactBtnBlock />
          <RelatedProjects relatedProjects={relatedProjects} />
        </Layout>
      </section>
    )
  }
}
